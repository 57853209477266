import React from 'react';

import Layout from '../components/layout/public';
import Hero from '../components/public/page-hero';
import Callout from '../components/public/callout';
import Pricing from '../components/public/pricing';
import Faq from '../components/public/faq';
import SEO from '../components/seo';

const PricingPage = () => (
	<Layout>
		<SEO
			title="Frequently asked questions"
			description={`Find answers to common questions related to our DNS servers and our ad blocking technology. Keep yourself and your family safe while browsing the internet.`}
		/>

		<Hero title={'Frequently asked questions'} style={{
			marginBottom: 50,
		}}/>

		<Faq showtitle={false} style={{
			marginBottom: 50,
		}}/>

		<Pricing style={{
			marginBottom: 50,
		}}/>

		<Callout style={{
			marginBottom: 50,
		}}/>
	</Layout>
);


export default PricingPage;
